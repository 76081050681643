<template>
  <v-row
      align="center"
      justify="center"
  >
    <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
    >
      <v-card class="elevation-12">
        <v-toolbar
            color="primary"
            dark
            flat
        >
          <v-toolbar-title class="text-center">Reset your password</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" autocomplete="no">
            <v-card-text class="pt-6 pb-0">
              <ValidationProvider tag="div" rules="required|email" name="Email" v-slot="{ errors }">
                <v-text-field
                    label="Email"
                    outlined
                    name="email"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    :error-messages="errors"
                    v-model="email"
                    readonly
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider tag="div" rules="required|min:8" name="password" v-slot="{ errors }">
                <v-text-field
                    id="password"
                    outlined
                    label="Password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    :error-messages="errors"
                    v-model="password"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider tag="div" rules="required|password:@password" name="password confirmation" v-slot="{ errors }">
                <v-text-field
                    id="password_confirmation"
                    outlined
                    label="Confirm Password"
                    name="password_confirmation"
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    :error-messages="errors"
                    v-model="passwordConfirmation"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-btn block :loading="loading" color="primary" type="submit">Reset Password</v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <router-link to="/login">
        <p class="mt-4 text-center">&#8592; Back to login</p>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

import {mapActions} from "vuex";
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate';

localize({
  en: {
    fields: {
      password: {
        min: 'Password must be greater than 8 characters',
      }
    }
  }
});

export default {
  name: 'Home',
  title: 'Set Password',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    token: String,
    email: String,
  },
  data: () => ({
    loading: false,
    password: null,
    passwordConfirmation: null
  }),
  methods: {
    ...mapActions({
      setPassword: 'auth/setPassword',
      triggerToast: 'toast/trigger',
    }),
    onSubmit: async function() {
      this.loading = true;
      if(await this.setPassword({email: this.email, password: this.password, password_confirmation: this.passwordConfirmation, token: this.token})) {
        await this.$router.push('/')
      }
      this.loading = false;
    }
  },
}
</script>