<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2" class="d-flex justify-space-between">
          <h1 class="ml-2">My Centres</h1>
          <v-btn float="right" class="mt-2" @click="$router.push({name: 'AddCentre'})">Add Centre</v-btn>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-data-table
              :headers="[
                { text: 'Name', value: 'name' },
                { text: 'City', value: 'city' },
                { text: 'Location', value: 'address', sortable: false },
                { text: 'Services', value: 'service', sortable: false },
                { text: 'Website', value: 'website', sortable: false },
              ]"
              :loading="loading"
              :items="properties"
              :footer-props="{
                  itemsPerPageOptions: [5, 10, 25, 50, 100]
              }"
          >
          <template v-slot:item.website="{ item }">
            <a :href="item.website" target="_blank" class="text-no-wrap">Contact Centre</a>
          </template>
          <template v-slot:item.service="{ item }">
            <span>{{ findProperty(item.id) }}</span>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ViewCentres',
  title: 'My Centres',
  async mounted() {
    await this.getProperties()
    await this.loadProperties()
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      properties: 'auth/properties',
      loading: 'auth/loading',
      allProperties: 'property/allProperties',
      propertiesLoading: 'property/propertiesLoading',
    }),
  },
  methods: {
    ...mapActions({
      getProperties: 'auth/me',
      loadProperties: 'property/getProperties',
    }),

    findProperty(propertyId) {
      return this.allProperties.find((p) => {
        return p.id === propertyId
      })?.rooms.map(r => {
        return r.type
      }).filter((value, index, array) => {
        return array.indexOf(value) === index
      }).join(', ')
    }
  }
}
</script>