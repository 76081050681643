<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" offset-md="2" class="d-flex justify-space-start align-center">
        <v-btn class="mr-6" @click="$router.push({ name: 'ViewCentres'}).catch(err => {})">
          Back
        </v-btn>
        <h1>Choose centre</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-data-table
            :headers="[
            { text: 'Name', value: 'name' },
            { text: 'City', value: 'city' },
            { text: 'Location', value: 'address', sortable: false },
            { text: 'Services', value: 'service', sortable: false },
            { text: 'Website', value: 'website', sortable: false },
            { text: 'Actions', value: 'actions', sortable: false },
          ]"
            :items="selectedProperties"
            :items-per-page="10"
            :loading="propertiesLoading || loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 25, 50, 100]
          }"
        >
        <template v-slot:item.website="{ item }">
          <a :href="item.website" target="_blank" class="text-no-wrap">Contact Centre</a>
        </template>
          <template v-slot:item.actions="{ item }">
            <v-spacer></v-spacer>
            <v-btn class="float-left" small color="success" @click="openModal(item)">
              Add Centre
            </v-btn>
          </template>
          <template v-slot:item.service="{ item }">
            <span>{{ findProperty(item.id) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" v-if="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedProperty.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              Are you sure that you want to add yourself to this centre?
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" @click="close">Cancel</v-btn>
          <v-btn color="success" @click="handleSubmit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CustomersRepository from "@/api/CustomersRepository";

export default {
  name: 'AddCentre',
  title: 'Add Centre',
  components: {
  },
  async mounted() {
    await this.getProperties()
  },
  data: () => ({
    dialog: false,
    loading: false,
    selectedProperty: null
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user',
      properties: 'auth/properties',
      allProperties: 'property/allProperties',
      propertiesLoading: 'property/propertiesLoading',
    }),

    selectedProperties() {
      return this.allProperties.filter((p) => {
        return !this.properties.find((sp) => {
          return sp.id === p.id
        })
      })
    },
  },
  methods: {
    ...mapActions({
      getProperties: 'property/getProperties',
    }),

    findProperty(propertyId) {
      return this.allProperties.find((p) => {
        return p.id === propertyId
      })?.rooms.map(r => {
        return r.type
      }).filter((value, index, array) => {
        return array.indexOf(value) === index
      }).join(', ')
    },
    openModal(property) {
      this.dialog = true
      this.selectedProperty = property
    },
    close() {
      this.selectedProperty = null;
      this.dialog = false
    },
    async handleSubmit(){
      try {
        this.loading = true
        await CustomersRepository.associateProperties(this.user.customer.uuid, [this.selectedProperty.id])
        this.$router.push({ name: 'ViewCentres'})
        this.dialog = false;
        this.loading = false
      } catch (e) {
        this.dialog = false;
        this.loading = false
      }
    },
  },
}
</script>