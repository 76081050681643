<template>
  <div>
      <v-row>
        <v-col cols="12" sm="6">
          <h1>{{pet.name}}'s Vaccination details</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="subheading">To protect all of our guests we require complete and valid vaccinations for all dogs that attend. Please upload photos or scans of {{pet.name}}'s vaccinations.</span>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col cols="12" sm="6" class="py-0">
          <dropzone-upload
              ref="dropzone"
              :useCustomSlot="true"
              :files.sync="files"
              :loading.sync="uploading"
              :options='{
                           maxFilesize: 25,
                           acceptedFiles: "image/*,application/pdf"
                         }'
          >
            <div><v-icon class="mr-2">mdi-upload</v-icon>Click to upload vaccination files</div>
          </dropzone-upload>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-textarea
              v-model="comment"
              label="Comments (optional)"
              auto-grow
              outlined
              rows="4"
              hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="caption">{{ reservation.property.name }} requires  an up to date C5 or equivalent vaccination.</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 :loading="loading"
                 :disabled="uploading || files.length === 0"
                 @click="onSubmit"
          >
            Save
          </v-btn>
          <v-btn block
                 class="mt-3"
          @click="$router.push({ name: 'OnboardingConfirmation', params: {id: reservation.id } })"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { mapGetters} from "vuex";
import DropzoneUpload from "@/components/DropzoneUpload";
import PetsRepository from "@/api/PetsRepository";
import store from "@/store";
export default {
  name: 'BookTrialDayDetails',
  title: 'Book a trial day | Vaccination Details',
  components: {
    DropzoneUpload,
  },
  async beforeRouteEnter(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["onboardingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["onboardingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  data: () => ({
    comment: null,
    loading: false,
    files: [],
    uploading: false,
  }),
  computed: {
    ...mapGetters({
      pet: 'onboardingConfirmation/pet',
      reservation: 'onboardingConfirmation/reservation',
    }),
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      let attachments = this.files.map(function (item) {
        return {
          temporaryFilename: item.temporaryFilename,
          filename: item.name,
          type: item.type,
        }
      })
      let response = await PetsRepository.uploadVaccinations(this.reservation.property.id, this.pet.uuid, attachments, this.comment)
      this.loading = false;
      if(response === 'success') {
        this.$router.push({ name: 'OnboardingConfirmation', params: {id: this.reservation.id } });
      }

    }
  },
}
</script>