<template>
  <v-row
      align="center"
      justify="center"
  >
    <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
    >
      <v-card class="elevation-12">
        <v-toolbar
            color="primary"
            dark
            flat
        >
          <v-toolbar-title class="text-center">Login to your account</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" autocomplete="no">
            <v-card-text class="pt-6 pb-0">
              <ValidationProvider tag="div" rules="required|email" vid="email" name="Email" v-slot="{ errors }">
                <v-text-field
                    label="Email"
                    outlined
                    name="email"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    :error-messages="errors"
                    v-model="email"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider tag="div" rules="required" name="Password" v-slot="{ errors }">
                <v-text-field
                    id="password"
                    outlined
                    label="Password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    :error-messages="errors"
                    v-model="password"
                ></v-text-field>
              </ValidationProvider>
              <v-checkbox
                  class="mt-0"
                  v-model="remember"
                  label="Remember this device"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn block :loading="loading" color="primary" type="submit">Login</v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <router-link to="/password/reset">
        <p class="mt-4 text-center">Forgot your password?</p>
      </router-link>
      <router-link to="/register">
        <p class="mt-4 text-center">Logging in for the first time? Register your account &#8594;</p>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

import {mapActions} from "vuex";
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Home',
  title: 'Login',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    loading: false,
    email: null,
    password: null,
    remember: false,
  }),
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
    }),
    onSubmit: async function() {
      this.loading = true;
      try {
        await this.signIn({email: this.email, password: this.password, remember: this.remember ? 'on' : null})
        let url = this.$route.query.redirect || '/'
        await this.$router.push(url)
      }
      catch(error){
        this.password = '';
        this.$refs.form.setErrors(error.response.data.errors);
      }

      this.loading = false;
    }
  },
}
</script>