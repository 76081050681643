<template>
    <div>
        <v-row>
            <v-col class="pb-0" cols="12" sm="6" :align="$vuetify.breakpoint.xs ? 'center' : ''">
                <h1>Medications</h1>
            </v-col>
            <v-col align="right" cols="12" sm="6">
                <v-btn class="mt-2" :block="$vuetify.breakpoint.xs ? true : false" @click="$router.push({name: 'AddMedication'})"> Add medication</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-0 px-0">
                <v-list>
                    <v-divider/>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        v-if="loading"
                    />
                    <v-list-item-group>
                        <template v-for="(item, index) in items">
                                <v-list-item :key="item.id">
                                    <template v-slot:default>
                                    <v-list-item-content>
                                        <text-view :text="item.message"> </text-view>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon color="red" @click="deleteMedication(item.id)">mdi-delete</v-icon>
                                    </v-list-item-action>
                                    </template>
                                </v-list-item>
                            <v-divider
                                v-if="index + 1 < items.length"
                                :key="'div_' + index"
                            />
                        </template>
                        <v-list-item v-if="items.length === 0 && loading === false" key="no-data" class="text-center">
                            <p class="ma-3 text-center">{{pet.name}} doesn't have any medications.</p>
                        </v-list-item>
                        <v-divider />
                    </v-list-item-group>
                </v-list>
                <v-btn
                    v-if="items.length !== 0"
                    outlined
                    :loading="btnLoading"
                    class="mx-4 mt-2 float-right"
                    :disabled="page === lastPage"
                    @click="page++"
                >
                    Next
                </v-btn>
                <v-btn
                    v-if="items.length !== 0"
                    outlined
                    :loading="btnLoading"
                    class="mx-4 mt-2 float-left float-sm-right"
                    :disabled="page === 1"
                    @click="page--"
                >
                    Previous
                </v-btn>
            </v-col>
        </v-row>
        <general-confirmation-dialog
            headline="Delete medication"
            message="Are you sure you want to delete this medication?"
            @confirmed="confirmDeleteMedication()"
            v-model="openDeleteModal"
        />
    </div>
</template>

<script>
import PetsRepository from "@/api/PetsRepository";
import { mapGetters } from "vuex";
import store from "@/store";
import GeneralConfirmationDialog from '../components/GeneralConfirmationDialog.vue'
import TextView from "../components/modules/ui/TextViewFormatter.vue";

export default {
    components: {
        GeneralConfirmationDialog,
        TextView,
    },
    mounted() {
        this.load()
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch("pet/get",to.params.id)
        next();
    },
    async beforeRouteUpdate(to, from, next) {
      await store.dispatch("pet/get",to.params.id)
      next();
    },
    data: () => ({
        items: [],
        page: 1,
        lastPage: 1,
        loading: false,
        selected: null,
        total: 0,
        openDeleteModal: false,
        btnLoading: false,

    }),
    computed: {
        ...mapGetters({
            pet: 'pet/pet',
        }),
    },
    methods: {
        load: async function() {
            this.loading = true;
            this.btnLoading = true
            let response = await PetsRepository.getMedications(this.pet.uuid, this.page);
            this.items = response.data.data;
            this.lastPage = response.data.last_page
            this.btnLoading = false
            this.loading = false;
        },
        deleteMedication(id) {
            this.idToDelete = id
            this.openDeleteModal = true
        },
        async confirmDeleteMedication() {
            let response = await PetsRepository.deleteMedication(this.pet.uuid, this.idToDelete)
            if(response) {
                this.openDeletModal = false
                await this.load()
            }
        },
    },
    watch: {
        page(value) {
            this.load(value)
        },
    },
}
</script>
