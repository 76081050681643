var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('h1',{staticClass:"ml-2"},[_vm._v("My Centres")]),_c('v-btn',{staticClass:"mt-2",attrs:{"float":"right"},on:{"click":function($event){return _vm.$router.push({name: 'AddCentre'})}}},[_vm._v("Add Centre")])],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('v-data-table',{attrs:{"headers":[
              { text: 'Name', value: 'name' },
              { text: 'City', value: 'city' },
              { text: 'Location', value: 'address', sortable: false },
              { text: 'Services', value: 'service', sortable: false },
              { text: 'Website', value: 'website', sortable: false } ],"loading":_vm.loading,"items":_vm.properties,"footer-props":{
                itemsPerPageOptions: [5, 10, 25, 50, 100]
            }},scopedSlots:_vm._u([{key:"item.website",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticClass:"text-no-wrap",attrs:{"href":item.website,"target":"_blank"}},[_vm._v("Contact Centre")])]}},{key:"item.service",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.findProperty(item.id)))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }