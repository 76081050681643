<template>
    <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" ref="provider"  v-slot="{ errors }">
        <vue-tel-input-vuetify
            :disabled="readonly"
            v-model="currentValue"
            :name="name"
            :label="label"
            :dense="dense || readonly"
            :outlined="outlined"
            :error-messages="customErrors ? customErrors : errors"
            type="tel"
            defaultCountry="AU"
            @input="handleValidation"
            @blur="blurValidation"
            validCharactersOnly
            autocomplete="off"
            v-bind="bindProps"
            v-on:country-changed="countryChanged"
        />
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import { VueTelInputVuetify } from 'vue-tel-input-vuetify';

    export default {
        components: {
            ValidationProvider,
            VueTelInputVuetify
        },
        props: {
            value: {
                type: String,
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            vid: {
                type: String,
                default: undefined
            },
            max: {
                type: Number,
                default: null
            },
            label: {
                type: String,
                default: 'Phone'
            },
            outlined: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            dense: {
                type: Boolean,
                default: false,
            },
            initialValue: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            currentValue: '',
            edit:true,
            selectedCountry: '',
            error: false,
            customErrors: '',
            isFirstInput: true,
            number: '',
            bindProps: {
                mode: "international",
                placeholder: "Enter a phone number",
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                disabled:false,
                inputOptions: {
                    showDialCode: false
                }
            },
        }),
        methods: {
            handleValidation (number, event) {
                if (event.isValid === false && !number && this.rules === '') {
                    this.error = false
                } else if(event.isValid === false) {
                    this.error = true
                }
                else {
                    this.error = false
                    this.customErrors = ''

                }
            },
            blurValidation() {
                if(this.error) {
                    this.customErrors = 'Phone is not valid.'
                } else {
                    this.customErrors = ''
                }
            },
            countryChanged(country) {
                this.selectedCountry = country
                this.$emit('input', this.currentValue);
            },
        },
        watch: {
            currentValue () {
                // allows us to use v-model on our input.
                this.$emit('input', this.currentValue);
                this.$emit('errorValue', this.error)
            },
            value (val) {
                // allows us to use v-model on our input.
                this.currentValue = val;
            },
        },

    };
</script>
