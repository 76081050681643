<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <h1>My Pets</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-0 px-0">
        <v-list>
          <v-divider/>
          <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
          ></v-progress-linear>
          <v-list-item-group>
            <template v-for="(item, index) in items">
              <router-link :to="{name: 'ViewPet', params: {id: item.uuid}}" :key="item.id" tag="span">
              <v-list-item >
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-avatar color="primary" class="mr-3">
                        <v-img v-if="item.avatar_location !== null" :src="item.avatar_location"></v-img>
                        <span v-else class="white--text headline">{{ item.name.charAt(0) }}</span>
                      </v-avatar>
                      {{item.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                      <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </template>

              </v-list-item>
              </router-link>
              <v-divider
                  v-if="index + 1 < items.length"
                  :key="'div_' + index"
              ></v-divider>
            </template>
            <v-list-item v-if="items.length === 0 && loading === false" key="no-data" class="text-center">
              <p class="ma-3 text-center">You don't have any pets.</p>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-right"
            :disabled="page === lastPage"
            @click="page++"
        >
          Next
        </v-btn>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-left float-sm-right"
            :disabled="page === 1"
            @click="page--"
        >
          Previous
        </v-btn>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import PetsRepository from "@/api/PetsRepository";

export default {
  name: 'ViewPets',
  title: 'My Pets',
  components: {
  },
  mounted() {
   this.load()
  },
  data: () => ({
    items: [],
    page: 1,
    lastPage: 1,
    loading: false,
    selected: null,
    total: 0,
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  methods: {
    load: async function() {
      this.loading = true;
      let response = await PetsRepository.index();
      this.items = response.data;
      this.loading = false;
    },
  },
  watch: {
    page(value) {
      this.load(value)
    },
  },
}
</script>