<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <v-row>
                <v-col cols="12" md="6">
                    <h1>{{pet.name}}'s Medication details</h1>
                    <span class="subheading">If {{pet.name}} requires medication during their stay we require owners to complete this medication form prior to check-in</span>
                </v-col>
                </v-row>
                <v-row class="d-flex flex-column mt-5">
                    <v-col cols="12" md="6" class="py-0">
                        <text-input rules="required" placeholder="Name of the medication" name="Medication name" label="Medication name*" v-model="name"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <text-input rules="required" placeholder="What is this medication for?" name="Description" label="Description*" v-model="description"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <text-input rules="required" placeholder="e.g. 1 tablet or 0.5ml" name="Dose amount" label="Dose amount*" v-model="doseDetails"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <text-input placeholder="e.g. am, midday, pm" name="Dose time" label="Dose time (optional)" v-model="doseTime"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <text-input placeholder="e.g. daily, weekly on Mondays, on 23rd April" name="Dose frequency" label="Dose frequency (optional)" v-model="frequency"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-checkbox class="mt-0" label="Refrigeration required (optional)" v-model="refrigeration"/>
                    </v-col>
                </v-row>
                <v-row class="mb-0">
                <v-col cols="12" md="6" class="py-0">
                    <dropzone-upload
                        ref="dropzone"
                        :useCustomSlot="true"
                        :files.sync="files"
                        :loading.sync="uploading"
                        :options='{
                                    maxFilesize: 25,
                                    acceptedFiles: "image/*,application/pdf"
                                }'
                    >
                    <div style="color: rgba(0, 0, 0, 0.6)">
                      <v-icon class="mr-2">mdi-upload</v-icon> Click to upload a photo of the medication with instructions
                    </div>
                    </dropzone-upload>
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="12" md="6">
                    <v-textarea
                        v-model="comment"
                        label="Comments (optional)"
                        auto-grow
                        outlined
                        rows="4"
                        hide-details
                    />
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="12" md="6">
                    <v-btn color="success" block
                        :loading="loading"
                        :disabled="uploading"
                        type="submit"
                    >
                    Save
                    </v-btn>
                    <v-btn block
                        class="mt-3"
                    @click="$router.push({ name: 'MedicationList', params: {id: pet.uuid } })"
                    >
                    Back
                    </v-btn>
                </v-col>
                </v-row>
            </form>
        </ValidationObserver>
    </div>
  </template>
  
  <script>
  import { ValidationObserver } from "vee-validate";
  import { mapGetters} from "vuex";
  import DropzoneUpload from "@/components/DropzoneUpload";
  import PetsRepository from "@/api/PetsRepository";
  import TextInput from "../components/TextInputComponent.vue";
  import store from "@/store";

  export default {
    title: 'Upload Medication Details',
    components: {
      DropzoneUpload,
      TextInput,
      ValidationObserver,
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch("pet/get",to.params.id)
        next();
    },
    async beforeRouteUpdate(to, from, next) {
      await store.dispatch("pet/get",to.params.id)
      next();
    },
    data: () => ({
      comment: null,
      loading: false,
      files: [],
      uploading: false,
      name: '',
      description: '',
      selectedTime: '',
      doseDetails: '',
      refrigeration: '',
      frequency: '',
      doseTime: '',


    }),
    computed: {
      ...mapGetters({
        customer: 'auth/customer',
        pet: 'pet/pet',
      }),
    },
    methods: {
      async onSubmit () {
        this.loading = true;
        let attachments = this.files.map(function (item) {
          return {
            temporaryFilename: item.temporaryFilename,
            filename: item.name,
            type: item.type,
          }
        })
        let medication = {
            attachments: attachments,
            comment: this.comment,
            name: this.name,
            description: this.description,
            dose_details: this.doseDetails,
            refrigeration: this.refrigeration,
            time_of_day: this.doseTime,
            frequency: this.frequency,
        }
        let response = await PetsRepository.uploadMedication(this.pet.uuid, medication)
        this.loading = false;
        if(response) {
          this.$router.push({ name: 'MedicationList' });
        }
  
      }
    },
  }
  </script>