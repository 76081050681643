<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <h1>Join our membership</h1>
        <v-alert class="mt-2" dense type="info" color="success" text>Come to daycare as much as you want with a Membership! All memberships include 10% off retail, training and washes.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <span class="subheading">Location</span>
        <v-select
            v-model="selectedProperty"
            :items="properties"
            item-value="id"
            item-text="name"
            placeholder="Location"
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <span class="subheading">Membership Type</span>
        <select-input v-model="type"
                      :items="subscriptionTypes"
                      :loading="subscriptionsLoading"
                      item-value="uuid"
                      item-text="name"
                      noDataText="No memberships are available at this location"
        ></select-input>
      </v-col>
    </v-row>
    <v-row v-if="selectedSubscription !== null">
      <v-col cols="12" sm="6" class="pt-0 pb-5">
        <v-card
            outlined
            color="grey lighten-4"
        >
          <v-card-title>Membership details</v-card-title>
          <v-card-text>
            <p><b>Cost:</b> {{ selectedSubscription.amount | toCurrency }} per {{ humanReadableSubscriptionPeriod }} </p>
            <p><b>Included {{ selectedSubscription.type }} visits per {{ humanReadableSubscriptionPeriod }}:</b> {{ selectedSubscription.included_redemptions }}</p>
            <p><b>Cost of additional {{ selectedSubscription.type }} visits:</b> {{ selectedSubscription.additional_redemption_reference_amount | toCurrency }}</p>
            <p><b>Minimum term:</b> {{ selectedSubscription.min_intervals }} {{ selectedSubscription.interval }}(s)</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <span class="subheading mb-3">Credit card details</span>
        <stripe-element-card
            ref="stripeForm"
            :pk="stripePK"
            @token="handleToken"
            @loading="setLoading"
            :disableAdvancedFraudDetection="true"
        />
        <v-alert
            type="success"
            dense
            text
            icon="mdi-lock"
        >Your credit card details are securely encrypted and stored
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedSubscription !== null">
      <v-col cols="12" sm="6">
        <v-alert class="mt-2" dense type="info" text>
          By signing up to a membership, you agree to the <a :href="selectedSubscription.link_terms" target="_blank">Terms and Conditions</a>. Your membership will commence immediately, you will be charged today for the current billing period and then again at the commencement of the next billing period.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn color="success" block
               :disabled="!canSubmit"
               @click="handleClick"
               :loading="isLoading"
        >
          Create Membership
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '../store'
import SubscriptionsRepository from '../api/SubscriptionsRepository.js';
import SelectInput from "@/components/SelectInputComponent";
import {mapActions, mapGetters} from "vuex";
import { StripeElementCard } from '@vue-stripe/vue-stripe';


export default {
  title: 'Membership application',
  name: 'CreateMembership',
  components: {
    SelectInput,
    StripeElementCard,
  },
  props: ['id'],
  async beforeRouteUpdate (to, from, next) {
    if(to.params.id != undefined)
    {
      await store.dispatch("customer/get",to.params.id)
    }
    next();
  },

  async beforeRouteEnter (to, from, next) {
    if(to.params.id != undefined)
    {
      await store.dispatch("customer/get",to.params.id)
    }
    next();
  },
  mounted() {
    this.selectedProperty = this.properties[0].id;
  },
  beforeUnmount() {
    this.$refs.stripeForm.destroy();
  },
  computed: {
    ...mapGetters({
      properties: 'auth/properties',
      customer: 'auth/customer',
      subscriptionTypes : 'property/subscriptionTypes',
      subscriptionsLoading: 'property/subscriptionsLoading',
    }),
    canSubmit ()  {
      return this.type !== null;
    },
    selectedSubscription ()  {
      return this.type !== null ? this.subscriptionTypes.find(element => element.uuid === this.type) : null;
    },
    isLoading ()  {
      return (this.loading || this.tokenLoading);
    },
    humanReadableSubscriptionPeriod () {
      if(this.selectedSubscription !== null) {
        if(this.selectedSubscription.interval_count === 1) {
          return this.selectedSubscription.interval
        }
        else {
          return this.selectedSubscription.interval_count + ' ' + this.selectedSubscription.interval + 's'
        }
      }
      else return '';
    }
  },
  data () {
    return {
      loading: false,
      tokenLoading: false,
      type: null,
      selectedProperty: null,
      stripePK: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    }
  },
  methods: {
    ...mapActions({
      syncSubscriptions: 'property/syncSubscriptions',
      activateSubscription: 'auth/activateSubscription',
    }),
    async handleClick () {
      this.$refs.stripeForm.submit();

    },
    async handleToken (token) {
      this.loading = true;
      let payload = {
        customer_id: this.customer.uuid,
        subscription_type_id: this.type,
        stripe_token: token.id
      };
      //submit subscription creation request
      let success = await SubscriptionsRepository.create(this.selectedProperty, payload)
      this.loading = false;
      if(success) {
        this.type = null;
        this.$refs.stripeForm.clear();
        this.activateSubscription();
        this.$router.push({name: 'Home'}).then(() => {
          location.reload();
        })
      }

    },
    setLoading (loading) {
      this.tokenLoading = loading;
    },
  },
  watch: {
    selectedProperty(id) {
      this.type = null;
      this.syncSubscriptions(id);
    },
    subscriptionTypes(value) {
      if(value.length > 0) {
        this.type = value[0].uuid
      }
    }
  }
}
</script>
