<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :loading="loading" @click.prevent="handleClick">
    <slot></slot>
  </v-btn>
</template>

<script>

export default {
  data: () => ({
    loading: false,
  }),

  methods: {
    handleClick: async function () {
      this.loading = true;
      await this.$listeners.action();
      this.loading = false;
    },
  }
}
</script>
