<template>
  <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <ValidationProvider tag="div" :rules="rules" :name="label" :vid="vid" v-slot="{ errors }">
        <v-text-field
            name="date"
            v-model="dateFormatted"
            placeholder="dd/mm/yyyy"
            :dense="!outlined"
            :solo="!outlined"
            :hide-details="hideDetails"
            :flat="!active && !outlined"
            :readonly="!active"
            @blur="date = parseDate(dateFormatted)"
            v-on="on"
            :outlined="outlined"
            :label="label"
            autocomplete="off"
            :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
    </template>
    <v-date-picker v-model="date" no-title @input="menu1 = false" :disabled="!active" :min="min" :max="max" :allowed-dates="allowedDates"></v-date-picker>
  </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
var moment = require('moment');

export default {
  components: {
    ValidationProvider
  },
  props: {
    value: String,
    active: {
      type: Boolean,
      default: true
    },
    weekdaysOnly: {
      type: Boolean,
      default: false
    },
    min: {
      type: [String, Date],
      default: null
    },
    max: {
      type: [String, Date],
      default: null
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      date: '',
      dateFormatted: '',
      menu1: false,
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },

  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit('input', val);
    },
    value: {
      handler: function(value) {
        this.date = value;
      },
      immediate: true
    },
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    allowedDates (date) {
      if(this.weekdaysOnly) {
        let day = moment(date).day();
        return (!(day === 6 || day === 0));
      }
      else {
        return true;
      }
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>
