<template>
  <v-row >
    <v-col align="center">
      <h1>Page not found</h1>
      <router-link to="/">
        Return home
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  title: 'Page Not Found',
  components: {
  },
}
</script>