import BaseAPIRepository from './BaseAPIRepository.js'
import axios from "axios";

export default class FileUploadRepository extends BaseAPIRepository	{

    static getSignedURL(filename, mimeType) {
        return axios.post('/api/user/uploads/temporary-cloud', {
            filename: filename,
            mimeType: mimeType
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

}
