var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',{staticClass:"text-center"},[_vm._v("Register your account")]),_c('v-spacer')],1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"no"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card-text',{staticClass:"pt-6 pb-0"},[_c('ValidationProvider',{attrs:{"vid":"email","tag":"div","rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","outlined":"","name":"email","prepend-inner-icon":"mdi-account","type":"text","error-messages":errors,"disabled":_vm.emailDisabled},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","tag":"div","rules":"required|min:8","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","outlined":"","label":"Password","name":"password","prepend-inner-icon":"mdi-lock","type":"password","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|password:@password","name":"password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password_confirmation","outlined":"","label":"Confirm Password","name":"password_confirmation","prepend-inner-icon":"mdi-lock","type":"password","error-messages":errors},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","loading":_vm.loading,"color":"primary","type":"submit"}},[_vm._v("Register")])],1)],1)]}}])})],1),_c('router-link',{attrs:{"to":"/login"}},[_c('p',{staticClass:"mt-4 text-center"},[_vm._v("← Back to login")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }