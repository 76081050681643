var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-start align-center",attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('v-btn',{staticClass:"mr-6",on:{"click":function($event){_vm.$router.push({ name: 'ViewCentres'}).catch(function (err) {})}}},[_vm._v(" Back ")]),_c('h1',[_vm._v("Choose centre")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('v-data-table',{attrs:{"headers":[
          { text: 'Name', value: 'name' },
          { text: 'City', value: 'city' },
          { text: 'Location', value: 'address', sortable: false },
          { text: 'Services', value: 'service', sortable: false },
          { text: 'Website', value: 'website', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false } ],"items":_vm.selectedProperties,"items-per-page":10,"loading":_vm.propertiesLoading || _vm.loading,"footer-props":{
            itemsPerPageOptions: [5, 10, 25, 50, 100]
        }},scopedSlots:_vm._u([{key:"item.website",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"text-no-wrap",attrs:{"href":item.website,"target":"_blank"}},[_vm._v("Contact Centre")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-spacer'),_c('v-btn',{staticClass:"float-left",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" Add Centre ")])]}},{key:"item.service",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.findProperty(item.id)))])]}}])})],1)],1),(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.selectedProperty.name))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Are you sure that you want to add yourself to this centre? ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"light"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.handleSubmit}},[_vm._v("Submit")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }