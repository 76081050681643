<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <h1>Packages</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-0 px-0">
        <v-list>
          <v-subheader><v-badge inline :content="total" :value="total">ACTIVE</v-badge></v-subheader>
          <v-divider/>
          <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
          ></v-progress-linear>
          <v-list-item-group>
            <template v-for="(item, index) in items">
              <v-list-item :key="item.id">

                <template v-slot:default>

                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                          small
                          color="secondary"
                          label outlined
                      >
                        <v-icon small class="mr-1" >mdi-home</v-icon>{{ item.property.name }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-title class="mt-2">
                      <v-icon>mdi-ticket</v-icon> {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Expiry date: {{ item.expires_at | toDate}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{item.qty_available}} available
                  </v-list-item-action>
                </template>

              </v-list-item>

              <v-divider
                  v-if="index + 1 < items.length"
                  :key="'div_' + index"
              ></v-divider>
            </template>
            <v-list-item v-if="items.length === 0 && loading === false" key="no-data" class="text-center">
              <p class="ma-3 text-center">You do not have any active packages.</p>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-right"
            :disabled="page === lastPage"
            @click="page++"
        >
          Next
        </v-btn>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-left float-sm-right"
            :disabled="page === 1"
            @click="page--"
        >
          Previous
        </v-btn>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'ViewPackages',
  title: 'My Packages',
  components: {
  },
  mounted() {
   this.load()
  },
  data: () => ({
    items: [],
    page: 1,
    lastPage: 1,
    loading: false,
    selected: null,
    total: 0,
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  methods: {
    load: async function(page) {
      this.loading = true;
      await axios.get('/api/user/packages', {
        params: {
          page: page
        }
      }).then((response) => {
        this.items = response.data.data;
        this.lastPage = response.data.meta.last_page;
        this.total = response.data.meta.total;
      });
      this.loading = false;
    }
  },
  watch: {
    page(value) {
      this.load(value)
    },
  },
}
</script>