<template>
  <ValidationProvider tag="div" :rules="rules" :name="name || label" :vid="vid" v-slot="{ errors }">
    <v-select
        :value="value"
        @input="$emit('input', $event)"
        :items="items"
        :label="label"
        :error-messages="errors"
        :outlined="outlined"
        :solo="!outlined"
        :item-text="itemText"
        :item-value="itemValue"
        :loading="loading"
        :no-data-text="noDataText"
        :multiple="multiple"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInput',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    noDataText: {
      type: String,
      default: 'No items available'
    },
    label: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentValue: ''
  }),
  watch: {
    currentValue (val) {
      // allows us to use v-model on our input.
      this.$emit('input', val);
    },
    value (val) {
      // allows us to use v-model on our input.
      this.currentValue = val;
    }
  }
};
</script>