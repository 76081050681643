<template>
  <div>
        <v-row class="mb-4">
      <v-col cols="12" sm="6">
        <v-btn class="mb-1" outlined @click="$router.push({name: 'Home'})"><v-icon small>mdi-arrow-left</v-icon>Back</v-btn><h1>Reservation details</h1>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Date:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        <span v-if="reservation.type === 'boarding'"><b>From:</b> {{ reservation.date | toShortDate }} <b>To:</b> {{ reservation.date_to | toShortDate }}</span>
        <span v-else>{{ reservation.date | toShortDate }}</span>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Pet:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        <pet-chip-component :pet="reservation.pet"/>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Location:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        <v-chip
            small
            color="secondary"
            label outlined
        >
          <v-icon small class="mr-1" >mdi-home</v-icon>{{ reservation.property.name }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Type:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        <reservation-type-chip-component :type="reservation.type" />
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Service:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        {{ reservation.rate.name }}
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Status:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        <reservation-status-chip-component :status="reservation.status" />
      </v-col>
    </v-row>
    <v-row class="mb-5" v-if="reservation.is_recurring">
      <v-col cols="3" sm="2" class="py-0">
        <span class="subheading mr-5">Recurring:</span>
      </v-col>
      <v-col cols="9" sm="10" class="py-0">
        <b>This is a recurring reservation - From: </b> {{ reservation.recurring_meta.start | toDate }} <b>To: </b> {{ reservation.recurring_meta.end | toDate }} <b>on</b>
        <span v-for="day in reservation.recurring_meta.days" :key="day">
                            {{ day | toUCFirst }},
                        </span> <b> every </b>
        <span v-if="reservation.recurring_meta.frequency == 1">
                            week
                        </span>
        <span v-else>
                            {{ reservation.recurring_meta.frequency }} weeks
                        </span>
      </v-col>
    </v-row>
    <v-row v-if="reservation.status === 'confirmed'" class="mt-10">
      <v-col cols="12" sm="6" class="py-0">
        <v-btn block color="error" @click="selectedReservation = reservation" v-if="reservation.type !== 'boarding'">Cancel reservation</v-btn>
      </v-col>
    </v-row>
  <cancel-reservation-dialog-component :reservation.sync="selectedReservation" @success="refresh"/>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import store from '../store';
import PetChipComponent from "@/components/PetChipComponent";
import ReservationTypeChipComponent from "@/components/ReservationTypeChipComponent";
import ReservationStatusChipComponent from "@/components/ReservationStatusChipComponent";
import CancelReservationDialogComponent from "@/components/CancelReservationDialogComponent";

export default {
  name: 'CreateReservation',
  title: 'New Reservation',
  components: {
    CancelReservationDialogComponent,
    ReservationStatusChipComponent,
    ReservationTypeChipComponent,
    PetChipComponent,
  },
  async beforeRouteUpdate (to, from, next) {
    await store.dispatch("reservation/get",to.params.id);
    next();
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch("reservation/get",to.params.id);
    next();
  },
  data: () => ({
    selectedReservation: {},
  }),
  computed: {
    ...mapGetters({
      reservation: 'reservation/reservation'
    }),
  },
  methods: {
    ...mapActions({
      refresh: 'reservation/refresh',
    }),
  },
}
</script>