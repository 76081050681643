<template>
  <div>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>{{ pet.name }}'s trial day at {{ reservation.property.name }} on {{ reservation.date | toShortDate }} is confirmed.</h3>
        </v-col>
      </v-row>
    <v-row>
      <v-col align="center" cols="12" sm="6">
        <v-icon size="100" color="green">mdi-check-circle</v-icon>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col class="pb-0" cols="12" sm="6">
        <p v-if="tasksCompletedRate < 100" class="pb-0">To finalise your reservation we require some more information - this needs to be completed 24 hours prior to the trial:</p>
        <p v-else class="pb-0">Thank you for completing your profile. We look forward to meeting {{ pet.name }} on {{ reservation.date | toShortDate }}.</p>

      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="6">
        <v-list dense>
          <v-subheader>Next steps ({{ tasksCompletedRate }}% complete):</v-subheader>
          <v-divider></v-divider>
            <v-list-item link :disabled="tasks.pet_profile" @click="$router.push({name: 'OnboardingPetProfile', params: { id: reservation.id }})">
              <v-list-item-icon>
                <v-icon v-if="tasks.pet_profile" color="green">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Complete {{ pet.name }}'s profile</v-list-item-title>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          <v-divider></v-divider>
          <v-list-item link :disabled="tasks.vaccination_details" @click="$router.push({name: 'OnboardingVaccinationDetails', params: { id: reservation.id }})">
            <v-list-item-icon>
              <v-icon v-if="tasks.vaccination_details" color="green">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-check-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ pet.name }}'s vaccination details</v-list-item-title>
            <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
            <v-list-item link :disabled="tasks.pet_description" @click="$router.push({name: 'OnboardingAboutPet', params: { id: reservation.id }})">
              <v-list-item-icon>
                <v-icon v-if="tasks.pet_description" color="green">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Tell us more about {{ pet.name }}</v-list-item-title>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          <v-divider></v-divider>
          <v-list-item link :disabled="tasks.customer_profile" @click="$router.push({name: 'OnboardingCustomerProfile', params: { id: reservation.id }})">
              <v-list-item-icon>
                <v-icon v-if="tasks.customer_profile" color="green">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Complete your profile</v-list-item-title>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-col>
    </v-row>
    <img v-if="success === 'true' && reservation.property.facebook_tracking_pixel !== null" :src="`https://www.facebook.com/tr?id=${reservation.property.facebook_tracking_pixel}&ev=Lead`" height="1" width="1" style="display:none"/>
  </div>
</template>

<script>
import store from "@/store";

import {mapActions, mapGetters} from "vuex";
export default {
  name: 'BookTrialDayConfirmation',
  title: 'Book a trial day | Confirmation',
  components: {
  },
  props: ['success'],
  async beforeRouteUpdate (to, from, next) {
    await store.dispatch("onboardingConfirmation/getReservation",to.params.id);
    next();
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch("onboardingConfirmation/getReservation",to.params.id);
    next();
  },
  mounted() {
    if('VUE_APP_GA_TRACKING_ID' in process.env && this.success === 'true') {
      this.$gtag.event('NewCustomerReservation', {
        'event_category': 'daycare',
        'value': Math.round(this.reservation.price / 100)
      })
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      reservation: 'onboardingConfirmation/reservation',
      pet: 'onboardingConfirmation/pet',
      tasks: 'onboardingConfirmation/tasks',
      tasksCompletedRate: 'onboardingConfirmation/tasksCompletedRate',
    }),
  },
  methods: {
    ...mapActions({
      getQuote: 'newCustomerOnboarding/getQuote',
      createReservation: 'newCustomerOnboarding/createReservation',
    }),
  },
}
</script>