<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <h1>Invoices</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-0 px-0">
          <v-list>
            <v-row class="ma-0">
              <v-col cols="12" sm="6" class="py-0">
                <v-select
                  label="Status"
                  outlined
                  v-model="statusSelected"
                  :items="statuses"
                  item-value="value"
                  @change="load(page, statusSelected, typeSelect, selectedProperty)"
                />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-select
                  label="Property"
                  outlined
                  v-model="selectedProperty"
                  :items="user.customer.properties"
                  item-value="id"
                  item-text="name"
                  @change="load(page, statusSelected, typeSelect, selectedProperty)"
                />
              </v-col>
            </v-row>
            <v-divider/>
            <v-progress-linear
                indeterminate
                color="primary"
                v-if="loading"
            ></v-progress-linear>
            <div v-if="$vuetify.breakpoint.smAndUp">
              <div v-for="(item, index) in items" :key="item.id">
                <v-list-item :key="item.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip small color="secondary" label outlined>
                        <v-icon small class="mr-1" >mdi-home</v-icon>{{ item.property.name }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-title class="mt-2">
                      {{ item.amount_total | toCurrency}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Invoice #: {{item.id}} <v-icon v-if="item.stripe_id !== null" x-small>mdi-autorenew</v-icon> |
                      Status: 
                      <invoice-status-chip :status="item.status"></invoice-status-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text class="mr-5">{{ item.created_at | toShortDate}}</v-list-item-action-text>
                  <v-list-item-action>
                    <v-btn @click="openInvoice(item)" icon><v-icon>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-action>
                  <v-list-item-action v-if="item.status === 'open'">
                    <router-link
                        :to="{ name: 'PayInvoice', params: {id: item.id}}"
                        tag="span"
                    >
                      <v-btn color="primary">Pay</v-btn>
                    </router-link>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                    v-if="index + 1 < items.length"
                    :key="'div_' + index"
                ></v-divider>
                <v-list-item v-if="items.length === 0 && loading === false" key="no-data" class="text-center">
                  <p class="ma-3 text-center">There are no invoices available for download.</p>
                </v-list-item>
                <v-divider />
              </div>
            </div>
            <div v-else>
              <div v-for="(item, index) in items" :key="item.id">
                <v-list-item :key="item.id" class="d-block">
                  <template v-slot:default>
                    <div class="py-5">
                      <v-chip small color="secondary" label outlined>
                        <v-icon small class="mr-1" >mdi-home</v-icon>{{ item.property.name }}
                      </v-chip>
                      <div>
                        {{ item.amount_total | toCurrency}}
                      </div>
                      <div>
                        Invoice #: {{item.id}} <v-icon v-if="item.stripe_id !== null" x-small>mdi-autorenew</v-icon> |
                        Status: <invoice-status-chip :status="item.status"></invoice-status-chip>
                      </div>
                      <div class="mt-3 d-flex justify-space-between">
                        <span class="mr-3">{{ item.created_at | toShortDate}}</span>
                        <div>
                          <v-btn @click="openInvoice(item)" class="mr-3" icon><v-icon>mdi-open-in-new</v-icon></v-btn>
                          <router-link
                              v-if="item.status === 'open'"
                              :to="{ name: 'PayInvoice', params: {id: item.id}}"
                              tag="span"
                          >
                            <v-btn style="width: 110px;" color="primary">Pay</v-btn>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-list-item>
                <v-divider
                    v-if="index + 1 < items.length"
                    :key="'div_' + index"
                ></v-divider>
              </div>
              <v-list-item v-if="items.length === 0 && loading === false" key="no-data" class="text-center">
                <p class="ma-3 text-center">There are no invoices available for download.</p>
              </v-list-item>
              <v-divider />
            </div>
          </v-list>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-right"
            :disabled="page === lastPage"
            @click="page++"
        >
          Next
        </v-btn>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-left float-sm-right"
            :disabled="page === 1"
            @click="page--"
        >
          Previous
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";
import InvoiceStatusChip from '../components/InvoiceStatusChip.vue';

export default {
  name: 'Home',
  title: 'Home',
  components: {
    InvoiceStatusChip,
  },
  mounted() {
   this.load()
  },
  data: () => ({
    items: [],
    page: 1,
    lastPage: 1,
    loading: false,
    selected: null,
    total: 0,
    params: {},
    statusSelected: 'all',
    typeSelected: 0,
    statusSelect: 'all',
    typeSelect: 'all',
    statuses: [
                {text: 'All', value: 'all'},
                {text: 'Paid', value: 'paid'},
                {text: 'Open', value: 'open'},
              ],
    types: ['all', 'daycare', 'boarding'],
    selectedProperty: 'all'
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  methods: {
    load: async function(page, status, type, property) {
      this.loading = true;
      this.typeSelect = type;
      this.statusSelect = status;
      this.selectedProperty = property
      this.params = {
        ...this.params,
        property: this.selectedProperty
      }
      const statusValues = this.statuses.map(status => status.value);
      if (statusValues.includes(this.statusSelect)) {
        this.params = {
          ...this.params,
          status: this.statusSelect
        }
      }

      if (this.types.includes(this.typeSelect)) {
        this.params = {
          ...this.params,
          type: this.typeSelect
        }
      }

      if (page > 0){
        this.params = {
          ...this.params,
          page: page
        }
      }

      await axios.get('/api/user/invoices', {
        params: this.params
      }).then((response) => {
        this.items = response.data.data;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
      });
      this.loading = false;
    },
    openInvoice: function(item) {
      let url = process.env.VUE_APP_API_BASE_URL + '/api/user/property/' + item.property.id + '/invoices/' + item.id + '/download';
      window.open(url)
    }
  },
  watch: {
    page(value) {
      this.load(value)
    },
  },
}
</script>