<template>
  <div>
    <v-row>
      <v-col>
        <h1>You need to verify your email address to activate your account.</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn v-if="!recentlyCreated" color="primary" @click="triggerResend" :disabled="disabled" :loading="loading">{{ disabled ? 'Email sent' : 'Resend verification email'}}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

var moment = require('moment');

export default {
  name: 'Verify',
  title: 'Email Verification',
  beforeRouteEnter(to, from, next) {
    if(store.getters["auth/hasVerifiedEmail"] === true) { next('/')}
    else {next();}

  },
  data: () => ({
    loading: false,
    disabled: false,
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user',
    }),
    recentlyCreated() {
      return moment(this.user.created_at).isAfter(moment().subtract(1, 'hour'))
    },
  },
  methods: {
    ...mapActions({
      resendVerificationEmail: 'auth/resendVerificationEmail',
    }),
    triggerResend: async function() {
      this.loading = true;
      try {
        await this.resendVerificationEmail()
        this.disabled = true;
      }
      catch(error){
        //do nothing
      }
      this.loading = false;
    },
  },
}
</script>