<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Cancel reservation</span>
      </v-card-title>
      <v-card-text v-if="reservation.is_recurring">
        <v-row>
          <v-col cols="12">
            <b>This is a recurring reservation - From: </b> {{ reservation.recurring_meta.start | toDate }} <b>To: </b> {{ reservation.recurring_meta.end | toDate }} <b>on</b>
            <span v-for="day in reservation.recurring_meta.days" :key="day">
                            {{ day | toUCFirst }},
                        </span> <b> every </b>
            <span v-if="reservation.recurring_meta.frequency == 1">
                            week
                        </span>
            <span v-else>
                            {{ reservation.recurring_meta.frequency }} weeks
                        </span>
            <v-radio-group
                v-model="type"
                mandatory
            >
              <v-radio
                  label="Cancel this reservation only"
                  value="single"
              ></v-radio>
              <v-radio
                  label="Cancel this and all following reservations"
                  value="all"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12">
            <span v-if="reservation.current_cancellation_fee > 0">A late cancellation/no-show fee of {{ reservation.current_cancellation_fee |toCurrency }} will be payable if you cancel now.</span><span v-else>Are you sure you want to cancel this reservation?</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light" @click="close">Back</v-btn>
        <AsyncButton color="error" @action="handleSubmit">Confirm cancellation</AsyncButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ReservationsRepository from "../api/ReservationsRepository";
import AsyncButton from "./AsyncButtonComponent";
import { isEmpty } from 'lodash';


export default {
  components: {
    AsyncButton,
  },
  props: {
    reservation: Object
  },
  methods: {
    async handleSubmit()    {
      let invoice = await ReservationsRepository.cancel(this.reservation.property.id, this.reservation.id, this.type);
      this.$emit('success', invoice);
      this.close();
    },
    async openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('update:reservation', {});
      this.type = 'single';
    },
  },
  data () {
    return {
      dialog: false,
      type: 'single',
    }
  },
  watch: {
    reservation (value) {
      if(!isEmpty(value)) {
        this.openDialog()
      }
    },
  }
}
</script>