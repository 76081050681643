<template>
    <div>
      <div v-if="parseText(this.text)">
        <p v-for="(value, key) in formattedText.content" :key="`id-` + key"><b class="text-capitalize">{{ key.split('_').join(' ') }}:</b> {{ value }}</p>
      </div>
      <span v-else>{{ formattedText.content }}</span>
        <a class="blue--text text-caption" @click="toggleShowMore()" v-if="formattedText.truncated">
            {{ showMore ? 'Show less' : 'Show more' }}
        </a>
    </div>
</template>|

<script>
export default {
    props: {
        text: {
            
        }
    },
    computed: {
        formattedText() {
            if(typeof this.text !== 'string') {
              return {
                truncated: false,
                content: this.text
              }
            }

            if (this.parseText(this.text)) {
              const content = this.parseText(this.text)
              const truncated = this.text.length > 500
              if (truncated && !this.showMore) {
                delete content.comment
                delete content.refrigeration
                delete content.frequency
              }
              return {
                truncated,
                content
              }
            }

            const paragraphs = this.text.split('\n');
            const truncated = paragraphs.length > 3 || this.text.length > 500;
            const content = truncated && !this.showMore
                ? paragraphs.slice(0, 3).reduce((text, paragraph) => text.length < 500 ? text + paragraph.substring(0, 500 - text.length) + '\n' : text, '')
                : paragraphs.join('\n');
            return {
                truncated,
                content
            };
        }
    },
    data: () => ({
        showMore: false
    }),

    methods: {
      parseText(item) {
        try {
          return JSON.parse(item);
        } catch (e) {
          return false;
        }
      },
        toggleShowMore() {
           this.showMore = !this.showMore
        },
    }
}
</script>