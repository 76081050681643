<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="{ errors }">
    <v-text-field :type="type" :dense="dense" :value="value" @input="$emit('input', $event)" :label="label" :placeholder="placeholder" :error-messages="errors" :max="max" :hint="hint" :persistent-hint="persistentHint" outlined @blur="$emit('blur')"/>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    max: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: null
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['blur'],
  data: () => ({
  }),
};
</script>

