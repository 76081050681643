<template>
    <v-chip
           small
           label
           :color="statusColour"
           class="white--text"
         >{{ statusText }}
         </v-chip>
   </template>
   
   <script>
   
   export default {
       props: {
         status: String,
       },
       computed: {
           statusColour() {
         if(this.status === 'open') return 'blue';
         else if(this.status === 'paid') return 'green';
         else if(this.status === 'pending') return 'green lighten-3';
         else if(this.status === 'draft') return 'grey';
         else if(this.status === 'unbalanced') return 'error';
         else if(this.status === 'void') return 'blue-grey darken-2';
         else return 'primary';
       },
       statusText()  {
         return this.status.toUpperCase()
       }
     },
   
   }
   </script>