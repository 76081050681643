<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12" sm="6">
          <h1>Complete your profile and set a password</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h4>Emergency contact details</h4>
          <span class="text-caption">An alternate contact if we are unable to to reach you in an urgent situation.</span>
        </v-col>
      </v-row>
      <v-row class="mb-0">
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="customer.name_emergency"
                rules="required"
                label="Emergency contact name*"
                name="Emergency contact name"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="customer.email_emergency"
                rules="required|email"
                label="Emergency contact email*"
                name="Emergency contact email"
            ></text-input>
          </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <phone-number-input-component
            rules="required"
            label="Emergency contact phone*"
            name="Emergency contact phone"
            v-model="customerEmergencyPhone"
            @errorValue="getErrorValue"
            vid="phone_emergency"
            outlined
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <text-input
              v-model="customer.relationship_emergency"
              rules="required"
              label="Emergency relationship*"
              name="Emergency relationship"
          ></text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h4>Set a password to access your account:</h4>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col class="py-0" cols="12" sm="6">
          <text-input
              v-model="customer.password"
              rules="required|min:8"
              type="password"
              vid="password"
              hint="Minimum length: 8 characters"
              label="Password*"
              name="Password"
              persistent-hint
          ></text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 type="submit"
                 :loading="loading"
                 :disabled="error"
          >
            Save
          </v-btn>
          <v-btn block
                 class="mt-3"
          @click="$router.push({name: 'OnboardingConfirmation', params: {id: reservation.id}})"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapGetters} from "vuex";
import TextInput from "@/components/TextInputComponent";
import CustomersRepository from "@/api/CustomersRepository";
import PhoneNumberInputComponent from "../../components/PhoneNumberInputComponent.vue";
import store from "@/store";

export default {
  name: 'BookTrialDayDetails',
  title: 'Book a trial day | Your details',
  components: {
    ValidationObserver,
    TextInput,
    PhoneNumberInputComponent,
  },
  async beforeRouteEnter(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["onboardingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["onboardingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  data: () => ({
    loading: false,
    customer: {},
    error: false,
    customerEmergencyPhone: '',
  }),
  mounted() {
    this.customerEmergencyPhone = this.customer.phone_emergency
  },
  computed: {
    ...mapGetters({
      reservation: 'onboardingConfirmation/reservation',
    }),
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      this.customer.phone_emergency = this.customerEmergencyPhone
      let response = await CustomersRepository.updateOnboarding(this.customer);
      if(response === 'success') {
        this.loading = false;
        this.$router.push({ name: 'OnboardingConfirmation', params: {id: this.reservation.id } });
      }
      this.loading = false;
    },
    getErrorValue(error) {
      this.error = error
    },
  },
}
</script>