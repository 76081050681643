<template>
    <div :id="id" ref="dropzoneElement" class="dropzone" style="text-align: center; border: 1px solid rgba(0, 0, 0, 0.38)">
        <div v-if="useCustomSlot" class="dz-message">
            <slot>Drop files here to upload</slot>
        </div>
    </div>
</template>

<script>
import Dropzone from "dropzone";
import FileUploadRepository from "@/api/FileUploadRepository";

Dropzone.autoDiscover = false;

export default {
    props: {
        id: {
            type: String,
            required: false,
            default: "dropzone"
        },
        options: {
            type: Object,
            required: false,
            default: () => { return {};}
        },
        useCustomSlot: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    mounted() {
        this.dropzone = new Dropzone(
            this.$refs.dropzoneElement,
            this.dropzoneSettings
        );

        let vm = this;

        this.dropzone.on("success", function(file) {
            let files = this.getAcceptedFiles().filter(function(item) {
                return item.status === "success";
            });
            vm.$emit("success", file);
            vm.$emit("update:files", files);
        })

        this.dropzone.on("removedfile", function() {
            vm.$emit("update:files", this.getAcceptedFiles().filter(function(item) {
                return item.status === "success";
            }))
        })

        this.dropzone.on("addedfile", function(uploadProgress) {
            vm.loading = (uploadProgress !== 100);
            vm.$emit("update:loading", vm.loading)
        })

      this.dropzone.on("complete", function() {
        if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
            vm.$emit("update:loading", false)
        }
      })

        this.dropzone.on("reset", function() {
            vm.$emit("update:files", this.getAcceptedFiles().filter(function(item) {
                return item.status === "success";
            }))
        })
    },
    beforeDestroy() {
       this.dropzone.destroy();
    },
    data () {
        return {
            loading: false,
        }
    },
    computed: {
        dropzoneSettings() {
            let defaultValues = {
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                timeout: 600000,
            };
            Object.keys(this.options).forEach(function(key) {
                defaultValues[key] = this.options[key];
            }, this);
            defaultValues.url = files => {return files[0].signedUploadUrl};
            defaultValues.method = 'put';
            defaultValues.headers= [];
            defaultValues.addRemoveLinks = true,
            defaultValues.accept = async (file, done) => {
                let response = await FileUploadRepository.getSignedURL(file.name, file.type)
                if(response !== undefined) {
                    file.signedUploadUrl = response.signed_upload_url;
                    file.temporaryFilename = response.temporary_filename;
                }
                else {
                    //upload failed remove the file
                    this.dropzone.removeFile(file);
                }
                done();

            };
            defaultValues.sending = function(file, xhr) {
                xhr.setRequestHeader("Content-Type", file.type);
                let _send = xhr.send;
                xhr.send = function() {
                    _send.call(xhr, file);
                };
            }
            return defaultValues;
        }
    },
    methods: {
        reset() {
            this.dropzone.removeAllFiles(true   );
        },
    },

}
</script>
<style>
.dropzone:hover {
  border-color: #000!important;
}
</style>
