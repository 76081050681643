<template>
  <div>
    <v-col cols="12">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Create Reservation
          </v-stepper-step>
          <v-stepper-step :complete="step > 2" step="2">
            Confirmation
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row>
              <v-col cols="12" sm="6">
                <h1>Reservation Request</h1>
                <router-link
                  :to="{ name: 'CreateMembership' }"
                  tag="span"
                >
                  <v-alert
                    v-if="
                      !hasActiveSubscription && subscriptionsAvailable
                    "
                    class="mt-2"
                    dense
                    type="info"
                    color="green"
                    text
                    >Simplify your daycare experience and save with
                    our memberships.
                    <router-link :to="{ name: 'CreateMembership' }"
                      >View memberships</router-link
                    ></v-alert
                  ></router-link
                >
                <v-alert class="mt-2" dense type="info" text
                  >Our team will review your reservation request and
                  you will receive an email with your reservation
                  confirmation within 48 hours.</v-alert
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <span class="subheading">Location</span>
                <v-select
                  v-model="selectedProperty"
                  :items="properties"
                  item-value="id"
                  item-text="name"
                  placeholder="Location"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <span class="subheading"
                  >Select the pet(s) for the reservation</span
                >
                <v-select
                  v-model="selectedPets"
                  :items="pets"
                  item-value="uuid"
                  item-text="name"
                  chips
                  placeholder="Pet(s)"
                  multiple
                  outlined
                  deletable-chips
                  small-chips
                  :loading="loadingPets"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <span class="subheading">Reservation Type</span>
                <v-select
                  v-model="rate"
                  :items="rateList"
                  item-value="uuid"
                  item-text="text"
                  outlined
                  return-object
                  :loading="ratesLoading"
                  no-data-text="No services are available to be booked online"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <span class="subheading"
                  >Reservation date
                  <v-progress-circular
                    v-if="loadingAvailability"
                    size="12"
                    width="2"
                    indeterminate
                /></span>
                <v-row justify="center" class="my-5">
                  <v-date-picker
                    v-model="dates"
                    :min="today"
                    :multiple="multipleDates"
                    @update:picker-date="handleDateNavigation"
                    :allowed-dates="checkIfDateIsAllowed"
                  ></v-date-picker>
                </v-row>
              </v-col>
            </v-row>
            <recuring-reservation
            :min="multipleDates ? dates[0] : dates"
              v-model="recurringPayload"
            >
            </recuring-reservation>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <span class="subheading">Notes (optional)</span>
                <v-textarea
                  outlined
                  v-model="notes"
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-alert
                  v-if="showPolicyInfo"
                  class="mb-3"
                  dense
                  type="info"
                  text
                >
                  <span
                    v-if="rate.policy.free_cancellation_period > 0"
                  >
                    A late cancellation/no-show fee of
                    <span
                      v-if="
                        rate.policy.cancellation_charge_is_percent
                      "
                      >{{ rate.policy.cancellation_charge }}%</span
                    ><span v-else>{{
                      rate.policy.cancellation_charge | toCurrency
                    }}</span>
                    will apply if you cancel after
                    {{
                      rate.policy.free_cancellation_period_time
                        | toTime
                    }}
                    <span
                      v-if="rate.policy.free_cancellation_period == 1"
                      >the day</span
                    ><span v-else
                      >{{
                        rate.policy.free_cancellation_period
                      }}
                      days</span
                    >
                    prior to this reservation.
                  </span>
                  <span v-else>
                    A cancellation/no-show fee of
                    <span
                      v-if="
                        rate.policy.cancellation_charge_is_percent
                      "
                      >{{ rate.policy.cancellation_charge }}%</span
                    ><span v-else>{{
                      rate.policy.cancellation_charge | toCurrency
                    }}</span>
                    will apply if you cancel this reservation.
                  </span>
                </v-alert>
                <v-btn
                  color="success"
                  block
                  :disabled="!canSubmit"
                  @click="tryCreateReservations"
                  :loading="loading"
                >
                  <v-progress-circular
                    v-if="loadingQuote"
                    indeterminate
                    style="position: absolute; left: 0%"
                    size="14"
                    width="2"
                  ></v-progress-circular>
                  Create Reservation
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-col align="center" sm="12">
              <v-container>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <h4 class="font-weight-regular">
                      {{ unavailableDates }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0 mt-8 pt-3">
                    <h3 class="font-weight-regular">
                      Do you want to proceed with the reservation for
                      the available dates?
                    </h3>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="2" class="py-0 mt-7">
                    <async-button-component @action="backToStepOne" color="error">No</async-button-component>
                  </v-col>
                  <v-col cols="12" sm="2" class="py-0 mt-7">
                    <async-button-component @action="createReservations(true)" color="success"
                      >Yes
                    </async-button-component>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </div>
</template>

<script>
import moment from 'moment';
import RecuringReservation from '@/components/ReccuringReservationComponent';
import ReservationsRepository from '@/api/ReservationsRepository';
import { mapActions, mapGetters } from 'vuex';
import PetsRepository from '@/api/PetsRepository';
import _ from 'lodash';
import AsyncButtonComponent from '../components/AsyncButtonComponent.vue';

export default {
  name: 'CreateReservation',
  title: 'New Reservation',
  components: {
    RecuringReservation,
    AsyncButtonComponent,
  },
  mounted() {
    this.loadPets();
    if (this.properties !== null && this.properties.length === 1) {
      this.selectedProperty = this.properties[0].id;
    }
  },
  data: () => ({
    selectedPets: [],
    pets: [],
    dates: [],
    recurringPayload: null,
    loading: false,
    loadingPets: false,
    price: null,
    notes: null,
    selectedProperty: null,
    rate: null,
    availableDates: [],
    loadingAvailability: false,
    loadingQuote: false,
    step: 1,
    unavailableDates: '',
    multipleDates: true,
  }),
  computed: {
    ...mapGetters({
      properties: 'auth/properties',
      hasActiveSubscription: 'auth/hasActiveSubscription',
      subscriptionsAvailable: 'auth/subscriptionsAvailable',
      rates: 'property/rates',
      ratesLoading: 'property/ratesLoading',
    }),
    today() {
      return moment().format('Y-MM-DD');
    },
    canSubmit() {
      return (
        this.price >= 0 &&
        this.selectedPets.length > 0 &&
        this.recurringPayload.isValid &&
        this.selectedProperty !== null &&
        this.loadingQuote === false &&
        this.dates.length > 0
      );
    },
    showPolicyInfo() {
      return (
        this.rate !== null &&
        (this.rate.policy.cancellation_charge > 0 ||
          this.rate.policy.no_show_charge > 0)
      );
    },
    rateList() {
      return _.orderBy(
        this.rates.map((rate) => {
          rate.text = _.capitalize(rate.type) + ' - ' + rate.name;
          return rate;
        }),
        'text',
        'asc'
      );
    },
  },
  methods: {
    ...mapActions({
      syncRates: 'property/syncRates',
    }),
    async loadPets() {
      this.loadingPets = true;
      let response = await PetsRepository.index();
      this.pets = response.data;
      this.loadingPets = false;
    },
    async tryCreateReservations() {
      this.loading = true;
      let recurringPayload = this.multipleDates ? null : this.recurringPayload.data
      let response = await ReservationsRepository.createReservations(
        this.selectedProperty,
        this.dates,
        this.rate.uuid,
        this.selectedPets,
        recurringPayload,
        this.notes,
        false
      );
      if (!response.success) {
        this.step = 2;
        this.unavailableDates = response.message;
      } else if (response.success) {
        this.$router.push({ name: 'Home' });
      }
      this.loading = false;
    },
    async createReservations() {
      this.loading = true;
      let recurringPayload = this.multipleDates ? null : this.recurringPayload.data
      let response = await ReservationsRepository.createReservations(
        this.selectedProperty,
        this.dates,
        this.rate.uuid,
        this.selectedPets,
        recurringPayload,
        this.notes,
        true
      );
      if (response.success === true) {
        this.$router.push({ name: 'Home' });
      }
      this.loading = false;
    },
    backToStepOne() {
      this.step = 1;
    },
    handleDateNavigation(month) {
      let start = moment(month, 'YYYY-MM').startOf('month');
      let end = moment(start).endOf('month');
      this.date_start = start.format('YYYY-MM-DD');
      this.date_end = end.format('YYYY-MM-DD');
      this.getAvailability();
    },
    async getAvailability() {
      if (this.rate && this.selectedProperty) {
        this.loadingAvailability = true;
        const qty =
          this.selectedPets.length > 0 ? this.selectedPets.length : 1;
        this.availableDates = await ReservationsRepository.getAvailability(
          this.selectedProperty,
          this.rate.uuid,
          this.date_start,
          this.date_end,
          qty
        );
        this.loadingAvailability = false;
      } else {
        this.availableDates = [];
        this.dates = [];
      }
    },
    checkIfDateIsAllowed(val) {
      return this.availableDates.indexOf(val) !== -1;
    },
  },
  watch: {
    page(value) {
      this.loadReservations(value);
    },
    async dates(val) {
      if (val.length > 0 && this.rate !== null) {
        this.loadingQuote = true;
        let selectedDate = this.multipleDates ? this.dates[this.dates.length-1] : this.dates
        let response = await ReservationsRepository.getQuoteFromRate(
          this.selectedProperty,
          this.rate.uuid,
          selectedDate
        );
        this.loadingQuote = false;
        if (response >= 0) {
          this.price = response;
        }
      }
    },
    async rate() {
      this.price = null;
      // fetch new availability based on rate change
      this.getAvailability();
    },
    async selectedProperty(propertyId) {
      this.rate = null;
      await this.syncRates(propertyId);
      if (this.rateList.length > 0) {
        this.rate = this.rateList[0];
      }
    },
    selectedPets() {
      this.availableDates = [];
      this.dates = [];
      this.getAvailability();
    },
    recurringPayload(newVal, oldVal) {
      const isCustomToNonCustomChange =
      (newVal.data.frequency === 'custom' && oldVal?.data?.frequency !== 'custom') ||
      (newVal.data.frequency !== 'custom' && oldVal?.data?.frequency === 'custom');

      if (isCustomToNonCustomChange) {
          if (newVal.data.frequency === 'custom') {
              this.multipleDates = true;
              this.dates = typeof this.dates === 'string' && this.dates !== '' ? [this.dates] : [];
          } else {
              this.dates = this.dates.length === 1 ? this.dates[0] : '';
              this.multipleDates = false;
          }
      }
    },
  },
};
</script>
