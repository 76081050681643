<template>
    <div>
      <v-row>
        <v-col cols="12" md="3">
          <h1>Pay Invoice</h1>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-end">
          <v-btn block @click="openInvoice(invoiceResource)">Download Invoice</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div style="width: 100%" v-if="invoiceResource.amount_paid == 0">
            <v-col v-if="!hasCoupon" cols="12" class="pt-0" @click="showCoupon = !showCoupon">
              <span style="color: #4582EC; cursor: pointer;">{{ showCoupon ? `I don't have a coupon code` : 'Apply a coupon code' }}</span>
            </v-col>
            <v-col v-if="!hasCoupon && showCoupon" cols="12" class="py-0">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(applyCouponCode)" autocomplete="off">
                  <v-row class="d-flex justify-space-between" style="width: 100%">
                    <v-col>
                      <span class="subheading">Coupon code</span>
                      <text-input outlined dense hide-details v-model="code" name="Coupon code"></text-input>
                    </v-col>
                    <v-col class="mt-6">
                      <v-btn :loading="loading" type="submit" color="success" block :disabled="!code">Apply</v-btn>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>
            </v-col>
            <v-col v-else-if="hasCoupon && showCoupon" cols="12" class="py-0 mb-5">
              <b>Coupon {{ couponCode.code }} applied</b>
              <v-btn @click="removeCoupon" class="float-right ml-5" color="error" small>Remove</v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0 pb-5">
          <v-card outlined color="grey lighten-4">
            <v-card-title>Payment History</v-card-title>
            <v-card-text>
              <p><b>Invoice Total:</b> {{ invoiceResource.amount_total | toCurrency }} </p>
              <p><b>Paid Amount: </b> {{ invoiceResource.amount_paid | toCurrency }}</p>
              <p><b>Remaining Amount: </b> {{ (invoiceResource.amount_total - invoiceResource.amount_paid) | toCurrency }} </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <span class="subheading mb-3">Credit card details</span>
          <stripe-element-card
              ref="stripeForm"
              :pk="stripePK"
              @token="handleToken"
              @loading="setLoading"
              :disableAdvancedFraudDetection="true"
          />
          <v-alert
              type="success"
              dense
              text
              icon="mdi-lock"
          >Your credit card details are securely encrypted and stored
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn color="success" block
                 @click="handleClick"
                 :loading="isLoading"
          >
            Pay
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  import store from '../store'
  import PackagesRepository from '../api/PackagesRepository.js';
  import {mapGetters} from "vuex";
  import {StripeElementCard} from '@vue-stripe/vue-stripe';
  import {ValidationObserver} from 'vee-validate'
  import TextInput from "../components/TextInputComponent.vue";
  export default {
    title: 'Pay Invoice',
    name: 'PayInvoice',
    components: {
      StripeElementCard,
      ValidationObserver,
      TextInput,
    },
    props: ['id'],
    async beforeRouteEnter(to, from, next) {
      await store.dispatch("property/getInvoice", to.params.id)
      next();
    },
    async beforeRouteUpdate(to, from, next) {
      await store.dispatch("property/getInvoice", to.params.id)
      next();
    },
    beforeUnmount() {
      this.$refs.stripeForm.destroy();
    },
    mounted() {
      this.invoiceResource = this.invoice
    },
    computed: {
      ...mapGetters({
        properties: 'auth/properties',
        customer: 'auth/customer',
        invoice: 'property/invoice',
        invoiceLoading: 'property/invoiceLoading',
      }),
      isLoading() {
        return (this.loading || this.tokenLoading);
      },
      hasCoupon() {
        return this.couponCode !== '';
      }
    },
    data() {
      return {
        invoiceResource: {},
        loading: false,
        tokenLoading: false,
        type: null,
        quantity: null,
        couponCode: '',
        code: '',
        showCoupon: false,
        stripePK: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      }
    },
    methods: {
      async handleClick() {
        if (this.$refs.stripeForm) {
          return this.$refs.stripeForm.submit();
        }
        this.loading = true;
        let payload = {
          coupon_code: this.code,
          save_card: true
        };
        let success = await PackagesRepository.payInvoice(this.invoiceResource.id, payload)
        this.loading = false;
        if (success) {
          this.selectedPackage = null;
          this.$router.push({name: 'ViewInvoices'})
        }
      },
      async handleToken(token) {
        this.loading = true;
        let payload = {
          coupon_code: this.showCoupon ? this.code : '',
          stripe_token: token.id,
          save_card: true
        };
        let success = await PackagesRepository.payInvoice(this.invoiceResource.id, payload)
        this.loading = false;
        if (success) {
          this.$refs.stripeForm.clear();
          this.$router.push({name: 'ViewInvoices'})
        }
      },
      setLoading(loading) {
        this.tokenLoading = loading;
      },
      openInvoice: function(item) {
        let url = process.env.VUE_APP_API_BASE_URL + '/api/user/property/' + item.property.id + '/invoices/' + item.id + '/download';
        window.open(url)
      },
      async applyCouponCode() {
        this.loading = true;
        let response = await PackagesRepository.addInvoiceCoupon(this.invoiceResource.id, this.code);
        if (response) {
          this.invoiceResource = response
          this.couponCode = response.coupon
          this.showCoupon = true
        }
        this.loading = false;
      },
      removeCoupon() {
        this.loading = true
        this.code = null
        this.couponCode = ''
        this.loading = false
        this.invoiceResource = this.invoice
      },
    }
  }
  </script>