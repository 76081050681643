<template>
    <v-dialog v-model="localValue" max-width="500px" persistent>
        <v-card>
            <v-card-title style="word-break: break-word">
                <span class="headline">{{ headline }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        {{ message }}
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="light" @click="cancel">{{backButtonTitle}}</v-btn>
                <AsyncButton :color="color" @action="confirm">{{confirmButtonTitle}}</AsyncButton>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AsyncButton from './AsyncButtonComponent.vue'
import HasLocalValue from '../mixins/HasLocalValue.js'

export default {
    components: { AsyncButton },

    props: {
        headline: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'error',
        },
        backButtonTitle: {
            type: String,
            default: 'Cancel',
        },
        confirmButtonTitle: {
            type: String,
            default: 'Confirm',
        }
    },

    mixins: [HasLocalValue],

    methods: {
        async confirm ()    {
            await this.$listeners.confirmed()
            this.close()
        },

        async cancel() {
            this.$emit('cancelled')
            this.close()
        },

        close () {
            this.localValue = false
        },
    }
}
</script>
