export default {
    props: ['value'],

    data () {
        return {
            localValue: this.value,
        }
    },

    watch: {
        value (newValue) {
            this.localValue = newValue
        },

        localValue (newValue) {
            this.$emit('input', newValue)
        },
    },
}