<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12" sm="6">
          <h1>Tell us about {{ pet.name }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="subheading">Help us to get to know {{ pet.name }} by telling us a bit about them:</span>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col cols="12" sm="6" class="py-0">
          <ValidationProvider tag="div" rules="required" name="Notes" v-slot="{ errors }">
          <v-textarea
              :placeholder="`${pet.name} is sociable and loves to meet other dogs...`"
              v-model="notes"
              :error-messages="errors"
              auto-grow
              outlined
              rows="6"
          />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 type="submit"
                 :loading="loading"
          >
            Save
          </v-btn>
          <v-btn block
                 class="mt-3"
          @click="$router.push({ name: 'OnboardingConfirmation', params: {id: reservation.id } })"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters} from "vuex";
import PetsRepository from "@/api/PetsRepository";
import store from "@/store";
export default {
  name: 'BookTrialDayDetails',
  title: 'Book a trial day | Pet notes',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async beforeRouteEnter(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["onboardingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["onboardingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  data: () => ({
    notes: null,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      reservation: 'onboardingConfirmation/reservation',
      pet: 'onboardingConfirmation/pet',
    }),
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      let response = await PetsRepository.createDescription(this.pet.uuid, {message: this.notes})
      this.loading = false;
      if(response === 'success') {
        this.$router.push({ name: 'OnboardingConfirmation', params: {id: this.reservation.id } });
      }
    }
  },
}
</script>